import { NavLink } from 'react-router-dom'
import './NavePages.scss'
import PlusIcon from '../../images/plus.png'

function NavPage(props) {



    function scrollToTop() {
        window.scrollTo(0, 0)
    }

    return (
        <div className='NavPagesBlog'>
            <nav className='indent'>
                <h2>Outros assuntos</h2>
                <ul>
                    {props.hide === '1' ? null : (
                        <li>
                            <NavLink to='/blog-mascote-top-mascote-3d' onClick={scrollToTop}>
                                <p>Como um Mascote 3D Fortalece a Identidade da Marca</p>
                                <img src={PlusIcon} alt='Saiba Mais'></img>
                            </NavLink>
                        </li>
                    )}
                    {props.hide === '2' ? null : (
                        <li>
                            <NavLink to='/blog-mascote-top-mascote-para-empresa' onClick={scrollToTop}>
                                <p>Diferença entre Mascotes 2D e 3D: Qual é Melhor para Sua Empresa?</p>
                                <img src={PlusIcon} alt='Saiba Mais'></img>
                            </NavLink>
                        </li>
                    )}
                    {props.hide === '3' ? null : (
                        <li>
                            <NavLink to='/blog-mascote-top-criacao-de-mascote' onClick={scrollToTop}>
                                <p>Processo de Criação de um Mascote: Etapas Essenciais do Briefing ao Produto Final</p>
                                <img src={PlusIcon} alt='Saiba Mais'></img>
                            </NavLink>
                        </li>
                    )}
                    {props.hide === '4' ? null : (
                        <li>
                            <NavLink to='/blog-mascote-top-personagens-exclusivos' onClick={scrollToTop}>
                                <p>Mascotes para Eventos e Campanhas: Como Aumentar o Engajamento com Personagens Exclusivos</p>
                                <img src={PlusIcon} alt='Saiba Mais'></img>
                            </NavLink>
                        </li>
                    )}
                    {props.hide === '5' ? null : (
                        <li>
                            <NavLink to='/blog-mascote-top-interacao-mascotes' onClick={scrollToTop}>
                                <p>Como um Mascote 3D Pode Melhorar as Interações nas Redes Sociais</p>
                                <img src={PlusIcon} alt='Saiba Mais'></img>
                            </NavLink>
                        </li>
                    )}
                    {props.hide === '6' ? null : (
                        <li>
                            <NavLink to='/blog-mascotes-habitaveis' onClick={scrollToTop}>
                                <p>Por Que Escolher a Mascote Top para Seus Mascotes Habitáveis?</p>
                                <img src={PlusIcon} alt='Saiba Mais'></img>
                            </NavLink>
                        </li>
                    )}

                    {props.hide === '7' ? null : (
                        <li>
                            <NavLink to='/blog-mascote-3d-estatico-ou-modelagem-3d-360' onClick={scrollToTop}>
                                <p>Mascote 3D Estático ou Mascote 3D em 360 Graus?</p>
                                <img src={PlusIcon} alt='Saiba Mais'></img>
                            </NavLink>
                        </li>
                    )}

                    {props.hide === '8' ? null : (
                        <li>
                            <NavLink to='/mascotes-de-vestir-fantasias-personagens' onClick={scrollToTop}>
                                <p>Mascotes Habitáveis, Mascotes de Vestir e Fantasias de Personagens</p>
                                <img src={PlusIcon} alt='Saiba Mais'></img>
                            </NavLink>
                        </li>
                    )}
                </ul>
            </nav>
        </div>
    );
}

export default NavPage;