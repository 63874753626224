import "./Bpages.scss"
import NavPage from "./NavPages";
import ImagePort from "../ImagePort";
import fantasia5d from "../../images/fantasias/concept-mascote-de-vestir-bonus-solar-fantasia-boninho.jpg"
import fantasia5f from "../../images/fantasias/mascote-de-vestir-bonus-solar-fantasia-boninho.jpg"

function Bpage7() {
    return (
        <div id="bpage">
            <div className="indent">
                <h1>Mascotes Habitáveis, Mascotes de Vestir e Fantasias de Personagens</h1>
                <p>Quer dar um <strong>toque especial</strong> e memorável ao seu próximo evento? Nossos <strong>mascotes habitáveis</strong> e <strong>fantasias de personagens</strong> são a solução perfeita para trazer alegria e interação inesquecível para qualquer ocasião. Na <strong>Mascote Top</strong>, somos especialistas em transformar ideias em realidade com mascotes de alta qualidade que capturam a essência de cada personagem.</p>

                <h2>Mascotes de Vestir: Conexão e Diversão Garantidas!</h2>
                <p>Nossos <strong>mascotes de vestir</strong> são cuidadosamente projetados para garantir conforto e segurança para quem os usa, sem comprometer a mobilidade ou a expressividade. Eles são perfeitos para animar festas infantis, eventos corporativos, jogos esportivos e qualquer ambiente que beneficie de um pouco mais de magia e sorrisos.</p>

                <h2>Por que Escolher a Mascote Top?</h2>

                <p><span>Qualidade Superior:</span> Todos os nossos produtos são feitos com materiais de alta qualidade e projetados para oferecer o máximo de conforto e durabilidade.</p>
                <p><span>Personalização Completa:</span> Temos uma equipe de designers talentosos que podem criar qualquer personagem ou mascote que você deseja, garantindo que sua visão se torne realidade.</p>
                <p><span>Experiência Memorável:</span> Nossos mascotes e fantasias são projetados para criar interações memoráveis, aumentando o engajamento e deixando uma impressão duradoura em todos os participantes do evento.</p>

                <p>Invista em mascotes habitáveis, mascotes de vestir e fantasias de personagens da <strong>Mascote Top</strong> para garantir que seu próximo evento seja um sucesso retumbante. Entre em contato conosco hoje mesmo para descobrir como podemos ajudar a transformar seu evento em uma experiência extraordinária!</p>
                <br/>
                <div className="w_img">
                    <ImagePort image={fantasia5d} number={310} info={'Mascote de Vestir Concept - Boninho - Bônus Solar.'}></ImagePort>
                    <ImagePort image={fantasia5f} number={309} info={'Mascote de Vestir ou Mascote Habitável (Fantasia), Boninho - Bônus Solar'}></ImagePort>
                </div>

            </div>
            <NavPage hide={'8'}></NavPage>
        </div>
    )
}

export default Bpage7;