import Bpage8 from "../../components/blog-pages/Bpage8";

function Page7() {
    return (
        <div>
            <Bpage8></Bpage8>
        </div>
    )

}

export default Page7;