import ImagePort from "./ImagePort";
import bannerF from "../images/banner-mascote-habitavel-fantasia.jpg";
import bannerFMobile from "../images/banner-mascote-habitavel-fantasia-mobile.jpg";
import "./Fantasia.scss"
import fantasia1d from "../images/fantasias/Mascote3D-Personagem-einstein-digital.jpg"
import fantasia1f from "../images/fantasias/mascote-habitavel-fantasia-einstein.jpg"
import fantasia2d from "../images/fantasias/Mascote3D-Personagem-automall-digital.jpg"
import fantasia2f from "../images/fantasias/mascote-habitavel-fantasia-automall.jpg"
import fantasia3d from "../images/fantasias/Mascote3D-Personagem-staff-digital.jpg"
import fantasia3f from "../images/fantasias/mascote-habitavel-fantasia-staff.jpg"
import fantasia4d from "../images/fantasias/Mascote2D-Personagem-solzinho-digital.jpg"
import fantasia4f from "../images/fantasias/mascote-habitavel-fantasia-solzinho.jpg"
import fantasia5d from "../images/fantasias/concept-mascote-de-vestir-bonus-solar-fantasia-boninho.png"
import fantasia5f from "../images/fantasias/mascote-de-vestir-bonus-solar-fantasia-boninho.png"




function Fantasia() {
    return (
        <div id="fantasia">
            <div className="bannerf">
                <picture>
                    <source media="(max-width: 800px)" srcSet={bannerFMobile} alt="Banner Fantasias" />
                    <img className="d-block w-100" src={bannerF} alt="Banner Fantasias." />
                </picture>
            </div>
            <div className="content">
                <h1>Diretamente do Digital para o mundo real.<br /> (Fantasias e Mascotes Habitáveis)</h1>
                <div className="wrap">
                    <div className="box">
                        <h2>JOCA - GSA do Hospital Albert Einstein</h2>
                        <div className="w_img">
                            <ImagePort image={fantasia1d} number={301} info={'Mascote 3D - Joca GSA do Hospital Albert Einstein.'}></ImagePort>
                            <ImagePort image={fantasia1f} number={302} info={'Mascote Habitável (Fantasia), Joca GSA do Hospital Albert Einstein.'}></ImagePort>
                        </div>
                    </div>
                    <div className="box">
                        <h2>SAM - Show Auto Mall</h2>
                        <div className="w_img">
                            <ImagePort image={fantasia2d} number={303} info={'Mascote 3D - SAM - Show Auto Mall.'}></ImagePort>
                            <ImagePort image={fantasia2f} number={304} info={'Mascote Habitável (Fantasia), SAM - Show Auto Mall.'}></ImagePort>
                        </div>
                    </div>
                    <div className="box">
                        <h2>Staffinho - Staff do Brasil</h2>
                        <div className="w_img">
                            <ImagePort image={fantasia3d} number={305} info={'Mascote 3D - Staffinho - Staff'}></ImagePort>
                            <ImagePort image={fantasia3f} number={306} info={'Mascote Habitável (Fantasia), Staffinho - Staff'}></ImagePort>
                        </div>
                    </div>
                    <div className="box">
                        <h2>Boninho - Bônus Solar</h2>
                        <div className="w_img">
                            <ImagePort image={fantasia5d} number={308} info={'Mascote de Vestir Concept - Boninho - Bônus Solar.'}></ImagePort>
                            <ImagePort image={fantasia5f} number={309} info={'Mascote de Vestir ou Mascote Habitável (Fantasia), Boninho - Bônus Solar'}></ImagePort>
                        </div>
                    </div>
                    <div className="box">
                        <h2>Solar Meninos da Luz</h2>
                        <div className="w_img">
                            <ImagePort image={fantasia4d} number={307} info={'Mascote 2D - Solzinho - Meninos Solar.'}></ImagePort>
                            <ImagePort image={fantasia4f} number={308} info={'Mascote Habitável (Fantasia), Solzinho - Meninos Solar'}></ImagePort>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Fantasia;